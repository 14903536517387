export const phoneticData = {
  'en-GB': {
    name: 'British English',
    groups: {
      vowels: {
        title: 'Vowels',
        color: '#2196f3',
        phonemes: ['iː', 'ɪ', 'e', 'æ', 'ɑː', 'ɒ', 'ɐ', 'ɔː', 'ʊ', 'uː', 'ɜː', 'ə', 'ʌ', 'i']
      },
      diphthongs: {
        title: 'Diphthongs',
        color: '#4caf50',
        phonemes: ['eɪ', 'aɪ', 'ɔɪ', 'aʊ', 'əʊ', 'ɪə', 'eə', 'ʊə']
      },
      consonants: {
        title: 'Consonants',
        color: '#ff9800',
        phonemes: ['p', 'b', 't', 'd', 'k', 'g', 'ʧ', 'ʤ', 'f', 'v', 'θ', 'ð', 's', 'z', 'ʃ', 'ʒ', 'h', 'm', 'n', 'ŋ', 'l', 'r', 'j', 'w']
      },
      stress: {
        title: 'Stress & Intonation',
        color: '#9c27b0',
        phonemes: ['ˈ', 'ˌ', '↗', '↘', '↑', '↓', '|', '‖']
      }
    }
  },
  'en-US': {
    name: 'American English',
    groups: {
      vowels: {
        title: 'Vowels',
        color: '#2196f3',
        phonemes: ['i', 'ɪ', 'e', 'ɛ', 'æ', 'u', 'ʊ', 'o', 'ɔ', 'ɑ', 'ʌ', 'ə']
      },
      diphthongs: {
        title: 'Diphthongs',
        color: '#4caf50',
        phonemes: ['aɪ', 'aʊ', 'ɔɪ', 'oʊ', 'eɪ']
      },
      consonants: {
        title: 'Consonants',
        color: '#ff9800',
        phonemes: ['p', 'b', 't', 'd', 'k', 'g', 'f', 'v', 'θ', 'ð', 's', 'z', 'ʃ', 'ʒ', 'tʃ', 'dʒ', 'm', 'n', 'ŋ', 'l', 'r', 'w', 'j', 'h']
      },
      stress: {
        title: 'Stress & Intonation',
        color: '#9c27b0',
        phonemes: ['ˈ', 'ˌ', '↗', '↘', '↑', '↓', '|', '‖']
      }
    }
  },
  'fr-FR': {
    name: 'French',
    groups: {
      vowels: {
        title: 'Vowels',
        color: '#2196f3',
        phonemes: ['i', 'e', 'ɛ', 'a', 'ɑ', 'o', 'ɔ', 'u', 'y', 'ø', 'œ']
      },
      nasals: {
        title: 'Nasal Vowels',
        color: '#4caf50',
        phonemes: ['ɑ̃', 'ɛ̃', 'œ̃', 'ɔ̃']
      },
      semivowels: {
        title: 'Semivowels',
        color: '#ff9800',
        phonemes: ['w', 'ɥ', 'j']
      },
      consonants: {
        title: 'Consonants',
        color: '#f44336',
        phonemes: ['p', 'b', 't', 'd', 'k', 'g', 'f', 'v', 's', 'z', 'ʃ', 'ʒ', 'm', 'n', 'ɲ', 'ŋ', 'l', 'ʁ']
      },
      stress: {
        title: 'Stress & Intonation',
        color: '#9c27b0',
        phonemes: ['ˈ', 'ˌ', '↗', '↘', '↑', '↓', '|', '‖']
      }
    }
  },
  'es-ES': {
    name: 'Spanish',
    groups: {
      vowels: {
        title: 'Vowels',
        color: '#2196f3',
        phonemes: ['i', 'e', 'a', 'o', 'u']
      },
      semivowels: {
        title: 'Semivowels',
        color: '#4caf50',
        phonemes: ['j', 'w']
      },
      consonants: {
        title: 'Consonants',
        color: '#ff9800',
        phonemes: ['p', 'b', 't', 'd', 'k', 'g', 'ʧ', 'ʤ', 'f', 'θ', 's', 'ʃ', 'x', 'm', 'n', 'ɲ', 'ŋ', 'l', 'ʎ', 'r', 'ɾ', 'ʁ']
      },
      stress: {
        title: 'Stress & Intonation',
        color: '#9c27b0',
        phonemes: ['ˈ', 'ˌ', '↗', '↘', '↑', '↓', '|', '‖']
      }
    }
  },
  'ru-RU': {
    name: 'Russian',
    groups: {
      vowels: {
        title: 'Vowels',
        color: '#2196f3',
        phonemes: ['i', 'e', 'a', 'o', 'u', 'ɨ', 'ʲi', 'ʲe', 'ʲa', 'ʲo', 'ʲu']
      },
      consonants: {
        title: 'Consonants',
        color: '#ff9800',
        phonemes: ['p', 'pʲ', 'b', 'bʲ', 't', 'tʲ', 'd', 'dʲ', 'k', 'kʲ', 'g', 'gʲ', 'f', 'fʲ', 'v', 'vʲ', 's', 'sʲ', 'z', 'zʲ', 'ʂ', 'ʐ', 'x', 'xʲ', 'ts', 'tɕ', 'm', 'mʲ', 'n', 'nʲ', 'l', 'lʲ', 'r', 'rʲ', 'j']
      },
      stress: {
        title: 'Stress & Intonation',
        color: '#9c27b0',
        phonemes: ['ˈ', 'ˌ', '↗', '↘', '↑', '↓', '|', '‖']
      }
    }
  }
};

export const voicesByLanguage = {
  'en-GB': [
    { name: 'en-GB-SoniaNeural', displayName: 'Sonia (Female)' },
    { name: 'en-GB-RyanNeural', displayName: 'Ryan (Male)' },
    { name: 'en-GB-LibbyNeural', displayName: 'Libby (Female)' },
  ],
  'en-US': [
    { name: 'en-US-JennyNeural', displayName: 'Jenny (Female)' },
    { name: 'en-US-GuyNeural', displayName: 'Guy (Male)' },
    { name: 'en-US-AriaNeural', displayName: 'Aria (Female)' },
  ],
  'fr-FR': [
    { name: 'fr-FR-DeniseNeural', displayName: 'Denise (Female)' },
    { name: 'fr-FR-HenriNeural', displayName: 'Henri (Male)' }
  ],
  'es-ES': [
    { name: 'es-ES-ElviraNeural', displayName: 'Elvira (Female)' },
    { name: 'es-ES-AlvaroNeural', displayName: 'Alvaro (Male)' }
  ],
  'ru-RU': [
    { name: 'ru-RU-SvetlanaNeural', displayName: 'Svetlana (Female)' },
    { name: 'ru-RU-DmitryNeural', displayName: 'Dmitry (Male)' }
  ]
};
